.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bodyDiv {
  float:left; 
}

.footerDiv {
  float:left; 
  clear: left;
}


.prodSearchTextField {
  size: 90;
  margin-left: auto;
  margin-right: auto;
  color: white;
  padding-bottom: 0;
  margin-top: 0;
  font-weight: 500;
}

.logoCaraouselDivMain {
  margin-top: 90px;
  margin-bottom: 90px;
  margin-left: 100px;
  margin-right: 100px;
 }

.middleMain {
  margin-top: 60px;
  margin-bottom: 90px;
  margin-left: 100px;
  margin-right: 100px;
 }

 .lineChart {
  margin-top: 60px;
  margin-bottom: 90px;
  margin-left: 100px;
  margin-right: 100px;
  height: 225px;
  width: 450px;
  display: flex;
  justify-content: center;
 }

 

.subscriptions {
  display: flex;
  justify-content: center;
}


.subscriptionDisableButton{
  border: 0;
    line-height: 2.5;
    padding: 0 20px;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    border-radius: 10px;
    background-color: gray;
    background-image: linear-gradient(to top left,
                                      rgba(0, 0, 0, .2),
                                      rgba(0, 0, 0, .2) 30%,
                                      rgba(0, 0, 0, 0));
    box-shadow: inset 2px 2px 3px rgba(255, 255, 255, .6),
                inset -2px -2px 3px rgba(0, 0, 0, .6);
}

.subscriptionEnableButton{
  border: 0;
    line-height: 2.5;
    padding: 0 20px;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    border-radius: 10px;
    background-color: green;
    background-image: linear-gradient(to top left,
                                      rgba(0, 0, 0, .2),
                                      rgba(0, 0, 0, .2) 30%,
                                      rgba(0, 0, 0, 0));
    box-shadow: inset 2px 2px 3px rgba(255, 255, 255, .6),
                inset -2px -2px 3px rgba(0, 0, 0, .6);
}

.subscriptions table a{
  color: white;
}
.subscriptions table, th, td {
  border:2px solid;
  border-color: #3366ff;
}

.errorMsg {
  color: red
}

.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}